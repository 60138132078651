import React from 'react';
import styled from 'styled-components';
import { Fade, Zoom } from 'react-reveal';
import useMedia from '../../hooks/useMedia';
import vars from '../../utils/styles/vars';
import heroImage from '../../assets/images/landingHero.jpg';
import bottomLine from '../../assets/icons/landing-hero-bottom-line.svg';
import media from '../../utils/helpers/media';
import FooterImage from '../../assets/images/Vector.svg';

const HeroContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 97px 205px 55px;
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    height: 34px;
    position: absolute;
    left: 14.5%;
    right: 14.5%;
    bottom: -3px;
    background: url(${bottomLine}) no-repeat right top;
  }
  &:before {
    content: '';
    background-image: ${vars.heroBackground};
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  ${media.desktop`
    flex-direction: column;
    padding: 16px 0;
    align-items: center;
    background: #fff;
    overflow: hidden;
    &:after {
      background: none;
    }
    &:before {
      display: none;
    }
  ;`}

  .footer-img-small {
    position: absolute;
    bottom: -5px;
    z-index: 55;
  }
`;

const HeroImageContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 364px;
  height: 272px;
  margin-top: 3px;
  ${media.desktop`
    flex-direction: column;
    width: 100%;
    height: 100%;
  ;`}
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-left: 20px;
  ${media.desktop`
    width: 100%;
    margin: 0;
    padding: 20px 20px 0 20px;
  ;`}
`;

const HeroSlogan = styled.p`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
  margin: 0 0 17px;
  font-weight: 600;
  max-width: 470px;
  ${media.phone`
    margin: 28px 0 23px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    width: 100%;
  ;`}
`;

const HeroDescription = styled.p`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  margin-bottom: 51px;
  max-width: 537px;
  ${media.phone`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    font-weight: 600;
    text-align: center;
    padding: 0 28px;
    margin-bottom: 25px;
  ;`}
`;

const HeroSection = () => {
  const small = useMedia('(max-width: 992px)');

  return (
    <div>
      <HeroContainer>
        <HeroImageContainer>
          <Zoom>
            <HeroImage src={heroImage} alt="landing image" />
          </Zoom>
        </HeroImageContainer>
        <HeroText>
          <Fade>
            <HeroSlogan>
              Живите в своей квартире, не волнуясь ни о чем.
            </HeroSlogan>
            <HeroDescription>
              А если что-нибудь случится — у вас есть страховка от Манго.
            </HeroDescription>
          </Fade>
        </HeroText>
        {small && (
          <img src={FooterImage} alt="#" className="footer-img-small" />
        )}
      </HeroContainer>
    </div>
  );
};

export default HeroSection;
