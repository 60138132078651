import React from 'react';
import PropTypes from 'prop-types';
import InsuranceCase from './InsuranceCase';
import iconIncaution from '../../../assets/icons/insurance-incaution.svg';
import iconTech from '../../../assets/icons/insurance-tech.svg';

const caseList = [
  {
    id: 1,
    title: 'Собственная неосторожность\n и зыбывчивость',
    description:
      'Уходя, вы забыли закрыть кран, дверь в квартиру, или выключить утюг',
    icon: iconIncaution
  },
  {
    id: 2,
    title: 'Неосторожность друзей,\n детей и близких',
    description:
      'Ваши гости уронили телевизор, а ваш ребёнок разрисовал стены в квартире',
    icon: iconTech
  }
];

const NoInsurancePopup = ({ closeModal }) => {
  return (
    <InsuranceCase
      title="Когда не платим"
      description="Ситуации, которые вы можете контролировать, страховка не покрывает:"
      items={caseList}
      isCase={false}
      closeModal={closeModal}
    />
  );
};

NoInsurancePopup.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default NoInsurancePopup;
