import React from 'react';
import PropTypes from 'prop-types';
import InsuranceCase from './InsuranceCase';
import iconFire from '../../../assets/icons/insurance-fire.svg';
import iconExplosion from '../../../assets/icons/insurance-explosion.svg';
import iconThief from '../../../assets/icons/insurance-thief.svg';
import iconWater from '../../../assets/icons/insurance-water.svg';
import iconStorm from '../../../assets/icons/insurance-storm.svg';
import iconElectricity from '../../../assets/icons/insurance-electricity.svg';

const caseList = [
  {
    id: 1,
    title: 'Пожара',
    description:
      'Если ваша квартира и ее содержимое полностью или частично сгорели',
    icon: iconFire
  },
  {
    id: 4,
    title: 'Повреждений водой',
    description: 'Если вашу квартиру затопили соседи, или у вас прорвало трубу',
    icon: iconWater
  },
  {
    id: 2,
    title: 'Взрыва',
    description: 'Если что-то взорвалось не по вашей вине',
    icon: iconExplosion
  },
  {
    id: 5,
    title: 'Стихийных бедствий',
    description:
      'Случился ураган, землетрясение, или любой другой природный катаклизм',
    icon: iconStorm
  },
  {
    id: 3,
    title: 'Противоправных действий',
    description: 'Кража со взломом, грабеж, разбой, хулиганство, вандализм.',
    icon: iconThief
  },
  {
    id: 6,
    title: 'Перепада напряжения',
    description: 'Если из-за скачка напряжения сгорела бытовая техника',
    icon: iconElectricity
  }
];

const InsurancePopup = ({ closeModal }) => {
  return (
    <InsuranceCase
      title="Когда платим"
      description="Манго защищает все вещи и ремонт внутри жилья в случае:"
      items={caseList}
      isCase
      closeModal={closeModal}
    />
  );
};

InsurancePopup.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default InsurancePopup;
