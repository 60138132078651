import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Fade from 'react-reveal/Fade';
import ExamplesItem from './ExamplesItem';
import useMedia from '../../hooks/useMedia';
import media from '../../utils/helpers/media';
import arrowLeft from '../../assets/icons/arrow_left.svg';
import arrowRight from '../../assets/icons/arrow_right.svg';

const ExamplesSectionWrapper = styled(Fade)`
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(237, 239, 255, 0.37) 100%
  );
`;

const ExamplesSectionContainer = styled.div`
  padding: 10px 0 55px;
  max-width: 1024px;
  margin: 0 auto;
  ${media.desktop`
    padding: 13px 0 35px;
  ;`}
`;

const ExamplesSectionSlider = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 54px;
  border: 1px solid rgba(121, 76, 250, 0.2);
  border-radius: 16px;
  position: relative;
  ${media.desktop`
    border: none;
    padding: 0 10px 30px;
    max-width: 375px;
    margin: 0 auto;
  ;`}
`;

const ExamplesSectionTitle = styled.h2`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.25px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
  ${media.desktop`
    margin-bottom: 20px;
  ;`}
`;

const ExamplesArrow = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  z-index: 1;
  ${media.desktop`
    top: 82px;
  `}
`;

const ExamplesArrowLeft = styled(ExamplesArrow)`
  background: url(${arrowLeft}) no-repeat center center;
  left: 30px;
  ${media.desktop`
    left: 20px;
  `}
`;

const ExamplesArrowRight = styled(ExamplesArrow)`
  background: url(${arrowRight}) no-repeat center center;
  right: 30px;
  ${media.desktop`
    right: 20px;
  `}
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  ${media.desktop`
    margin: 20px 0;
  ;`}
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: ${props => (props.active ? '#5A4CFA' : '#CFD8DC')};
  box-shadow: ${props =>
    props.active ? '0px 4px 8px rgba(48, 79, 254, 0.24)' : ''};
  border-radius: 50%;
  margin-right: 8px;
  transition: all;
`;

const examplesList = [
  {
    id: 1,
    question:
      'Грабители взломали дверь\n в квартиру Коли и украли дорогой компьютер стоимостью 130 000 ₽.',
    answer: 'Да. Это кража. Заплатим за починку двери и новенький компьютер.'
  },
  {
    id: 2,
    question:
      'Антон ушел на работу, забыв закрыть воду в ванной и затопил соседей до первого этажа.',
    answer:
      'Да, но только соседям. Манго не защищает ваши вещи от вас самих, а вот соседские — да. '
  },
  {
    id: 3,
    question:
      'Гости Олега так бурно веселились, что сломали телевизор и дверь в спальню.',
    answer:
      'Нет. Манго не компенсирует то, что было сломано в вашем доме по вашей вине.'
  }
];

const ExamplesSection = () => {
  const [activeSlide, setActiveSlide] = useState(1);
  const small = useMedia('(max-width: 992px)');

  const prevSlide = () => {
    setActiveSlide(Math.max(activeSlide - 1, 1));
  };

  const nextSlide = () => {
    setActiveSlide(Math.min(activeSlide + 1, examplesList.length));
  };

  const changeSlide = value => {
    setActiveSlide(value + 1);
  };

  return (
    <ExamplesSectionWrapper>
      <ExamplesSectionContainer>
        <ExamplesSectionSlider>
          <ExamplesSectionTitle>Случаи из жизни</ExamplesSectionTitle>
          <Carousel
            arrowLeft={
              <ExamplesArrowLeft onClick={prevSlide} onKeyDown={prevSlide} />
            }
            arrowRight={
              <ExamplesArrowRight onClick={nextSlide} onKeyDown={nextSlide} />
            }
            addArrowClickHandler
            keepDirectionWhenDragging
            slidesPerPage={3}
            slidesPerScroll={1}
            onChange={changeSlide}
            value={activeSlide - 1}
            breakpoints={{
              992: {
                slidesPerPage: 1
              }
            }}
          >
            {examplesList.map(item => (
              <ExamplesItem key={item.id} item={item} />
            ))}
          </Carousel>
          {small && (
            <Dots>
              {examplesList.map(item => (
                <Dot key={item.id} active={activeSlide === item.id} />
              ))}
            </Dots>
          )}
        </ExamplesSectionSlider>
      </ExamplesSectionContainer>
    </ExamplesSectionWrapper>
  );
};

export default ExamplesSection;
