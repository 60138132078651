import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Overlay from '../../utils/common/Overlay';
import InsurancePopup from './PaymentPopup/InsurancePopup';
import NoInsurancePopup from './PaymentPopup/NoInsurancePopup';
import vars from '../../utils/styles/vars';
import media from '../../utils/helpers/media';
import closeWhite from '../../assets/icons/close-white.svg';
import checkWhite from '../../assets/icons/check-white.svg';

const PaymentTermsItemContainer = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  &:nth-child(odd) {
    padding-right: 34px;
  }
  &:nth-child(even) {
    padding-left: 34px;
  }
  &:nth-child(odd):after {
    content: '';
    height: 184px;
    width: 1px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #dfd6fe;
  }
  ${media.desktop`
    width: 100%;
    padding: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 58px;
    &:nth-child(odd), &:nth-child(even){
      padding: 0;
    }
    &:nth-child(odd):after {
      display: none;
    }
  ;`}
`;

const PaymentTermsItemImageContainer = styled.div`
  width: 131px;
  height: 111px;
  position: absolute;
  top: 0;
  left: ${props => (props.isCase ? '0px' : '35px')};
  margin-top: ${props => (props.isCase ? '13px' : '20px')};
  &:after {
    content: '';
    width: 34px;
    height: 34px;
    display: inline-block;
    position: absolute;
    bottom: ${props => (props.isCase ? '-10px' : '0px')};
    right: ${props => (props.isCase ? '0px' : '-10px')};
    border-radius: 50%;
    background-color: ${props => (props.isCase ? '#31CEB2' : '#E62167')};
    background-image: url(${props => (props.isCase ? checkWhite : closeWhite)});
    background-repeat: no-repeat;
    background-position: center center;
  }
  ${media.desktop`
    position: relative;
    margin-top: 10px;
    left: 0;
    &:after{
      bottom: ${props => (props.isCase ? '-10px' : '0')};
      right: ${props => (props.isCase ? '0' : '-6px')};
    }
  ;`}
`;

const PaymentTermsItemImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const PaymentTermsItemText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 162px;
  ${media.desktop`
    margin-left: 0;
    align-items: center;
  ;`}
`;

const PaymentTermsItemTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin-bottom: 21px;
  ${media.desktop`
    margin-left: 0;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  ;`}
`;

const PaymentTermsItemDescription = styled.p`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  max-width: 305px;
  margin-bottom: 18px;
  white-space: pre-line;
  ${media.desktop`
    margin-top: 20px;
    margin-bottom: 12px;
    font-family: Avenir Next;
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
  ;`}
`;

const PaymentTermsItemButton = styled.button`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  width: 150px;
  color: ${vars.colors.primaryColor};
  outline: none;
  cursor: pointer;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  ;`}
`;

const PaymentTermsItem = ({ item: { title, text, image, isCase } }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    document.body.classList.add('modal-open');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setIsModalOpen(false);
  };

  return (
    <PaymentTermsItemContainer>
      <PaymentTermsItemText>
        <Fade>
          <PaymentTermsItemTitle>{title}</PaymentTermsItemTitle>
        </Fade>
        <PaymentTermsItemImageContainer isCase={isCase}>
          <Fade>
            <PaymentTermsItemImage src={image} alt="" />
          </Fade>
        </PaymentTermsItemImageContainer>
        <PaymentTermsItemDescription>{text}</PaymentTermsItemDescription>
        <PaymentTermsItemButton onClick={openModal}>
          Узнать больше
        </PaymentTermsItemButton>
      </PaymentTermsItemText>
      {isModalOpen ? (
        <Overlay>
          {isCase ? (
            <InsurancePopup closeModal={closeModal} />
          ) : (
            <NoInsurancePopup closeModal={closeModal} />
          )}
        </Overlay>
      ) : (
        ''
      )}
    </PaymentTermsItemContainer>
  );
};

PaymentTermsItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    isCase: PropTypes.bool.isRequired
  }).isRequired
};

export default PaymentTermsItem;
