import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../../utils/common/Button';
import vars from '../../../utils/styles/vars';
import media from '../../../utils/helpers/media';
import useMedia from '../../../hooks/useMedia';
import closeWhite from '../../../assets/icons/close-white.svg';
import checkWhite from '../../../assets/icons/check-white.svg';
import logo from '../../../assets/images/header-home.svg';
import arraowBack from '../../../assets/icons/back.svg';

const InsuranceCaseContainer = styled.div`
  padding: 33px 30px 30px;
  width: 952px;
  min-height: 462px;
  margin: 110px auto 0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  ${media.phone`
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0 34px 34px;
    margin-top: 0;
    min-height: none;
    height: auto;
    justify-content: flex-start;
;`}
`;

const InsuranceCaseTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  font-weight: 500;
  text-align: center;
  position: relative;
  display: inline-block;
  padding-left: 40px;
  &:before {
    content: '';
    width: 34px;
    height: 34px;
    position: absolute;
    top: 5px;
    left: 0;
    display: inline-block;
    border-radius: 50%;
    background-color: ${props => (props.isCase ? '#31CEB2' : '#E62167')};
    background-image: url(${props => (props.isCase ? checkWhite : closeWhite)});
    background-repeat: no-repeat;
    background-position: center center;
  }
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    &:before{
      display: none;
    }
  ;`}
`;

const InsuranceCaseDescription = styled.p`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 50px;
  max-width: 535px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 35px;
;`}
`;

const InsuranceCaseList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  width: 100%;
`;

const InsuranceCaseItem = styled.li`
  margin-bottom: 30px;
  width: 423px;
  flex-grow: 0;
`;

const InsuranceCaseButton = styled(Button)`
  font-family: Gilroy;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  width: 235px;
  height: 56px;
  margin-top: auto;
  ${media.phone`
    width: 264px;
    height: 56px;
    margin-top: 80px;
    flex-shrink: 0;
  ;`}
`;

const InsuranceCaseItemTitle = styled.h3`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 80px;
  position: relative;
  white-space: pre-line;
  &:before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    background: ${props => `url(${props.icon})`} no-repeat center center;
  }
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.44px;
    text-align: left;
    padding-left: 70px;
    margin-bottom: 10px;
  ;`}
`;

const InsuranceCaseItemText = styled.p`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  padding-left: 80px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    padding-left: 70px;
  ;`}
`;

const InsuranceCloseButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 11px;
  right: 11px;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 10px;
    width: 20px;
    height: 2px;
    background-color: ${vars.colors.primaryColor};
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 10px;
    width: 20px;
    height: 2px;
    background-color: ${vars.colors.primaryColor};
    transform: rotate(-45deg);
  }
`;

const InsuranceMobileHeader = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 25px 0;
  width: 100%;
  margin-bottom: 35px;
`;
const InsuranceBackButton = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 25px;
  left: -34px;
  background: url(${arraowBack});
`;

const InsuranceCase = ({ title, description, items, closeModal, isCase }) => {
  const small = useMedia('(max-width: 992px)');
  return (
    <InsuranceCaseContainer>
      {small ? (
        <InsuranceMobileHeader>
          <InsuranceBackButton onClick={closeModal} />
          <img
            src={logo}
            width="108"
            height="28"
            alt="Логотип Манго"
            aria-label="Назад на главную"
          />
        </InsuranceMobileHeader>
      ) : (
        <InsuranceCloseButton onClick={closeModal} />
      )}
      <InsuranceCaseTitle isCase={isCase}>{title}</InsuranceCaseTitle>
      <InsuranceCaseDescription>{description}</InsuranceCaseDescription>
      <InsuranceCaseList>
        {items.map(item => (
          <InsuranceCaseItem key={item.id}>
            <InsuranceCaseItemTitle icon={item.icon}>
              {item.title}
            </InsuranceCaseItemTitle>
            <InsuranceCaseItemText>{item.description}</InsuranceCaseItemText>
          </InsuranceCaseItem>
        ))}
      </InsuranceCaseList>
      <InsuranceCaseButton onClick={closeModal} text="Ясно" />
    </InsuranceCaseContainer>
  );
};

InsuranceCase.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCase: PropTypes.bool.isRequired
};

export default InsuranceCase;
