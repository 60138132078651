import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal';
import AdvantageItem from './AdvantageItem';
import media from '../../utils/helpers/media';
import monitorIcon from '../../assets/icons/monitor.svg';
import urbanIcon from '../../assets/icons/urban.svg';
import repairIcon from '../../assets/icons/repair-small.svg';
import documentIcon from '../../assets/icons/bureaucracy.svg';

const AdvantageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Avenir Next;
  max-width: 1440px;
  margin: 0 auto;
  padding: 78px 11.5% 50px;
  ${media.phone`
    padding: 40px 36px 20px;
  ;`}
`;

const AdvantageTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin-bottom: 64px;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 36px;
  ;`}
`;

const AdvantageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  ${media.tablet`
    justify-content: center;
  ;`}
  ${media.phone`
    justify-content: flex-start;
  ;`}
`;

const AdvantageItemList = [
  {
    id: 1,
    icon: monitorIcon,
    title: 'У вас много дорогой техники',
    text:
      'Вы часто путешествуете и боитесь, что пока вас нет, вашу квартиру ограбят. Застрахуйте квартиру и не волнуйтесь ни о чём!'
  },
  {
    id: 2,
    icon: urbanIcon,
    title: 'Переехали в новостройку',
    text:
      'У соседей в вашей новостройке дорогой ремонт и вы не хотите портить отношения, если затопите их. С Манго нет причин для опасений.'
  },
  {
    id: 3,
    icon: repairIcon,
    title: 'Сделали ремонт',
    text:
      'Вы купили новую квартиру, сделали дорогой ремонт и опасаетесь, что соседи затопят вас.\n Манго всегда поможет!'
  },
  {
    id: 4,
    icon: documentIcon,
    title: 'Не любите бюрократию',
    text:
      'Не хотите возиться с оформлением документов, ждать агента для оценки ущерба. Манго страхование и никакой бюрократии.'
  }
];

const AdvantageSection = () => {
  return (
    <AdvantageContainer>
      <Fade top>
        <AdvantageTitle>Почему со страховкой лучше</AdvantageTitle>
      </Fade>
      <AdvantageList>
        {AdvantageItemList.map(item => (
          <AdvantageItem key={item.id} item={item} />
        ))}
      </AdvantageList>
    </AdvantageContainer>
  );
};

export default AdvantageSection;
