import React from 'react';
import styled from 'styled-components';
import Zoom from 'react-reveal';
import PropTypes from 'prop-types';
import media from '../../utils/helpers/media';

const AdvantageItemContainer = styled.li`
  margin-bottom: 61px;
  max-width: 450px;
  width: 45%;
  ${media.tablet`
    max-width: 285px;
    width: 100%;
    margin-bottom: 39px;
  ;`}
`;

const AdvantageItemTitle = styled.h3`
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 29px;
  padding-left: 40px;
  position: relative;
  display: flex;
  align-items: flex-start;
  &::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${props => props.icon});
    background-position: center center;
    background-repeat: no-repeat;
  }
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    padding-left: 45px;
    margin-bottom: 17px;
  ;`}
`;

const AdvantageItemText = styled.p`
  white-space: pre-line;
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    max-width: 272px;
  ;`}
`;

const AdvantageItem = ({ item: { icon, title, text } }) => {
  return (
    <AdvantageItemContainer>
      <Zoom>
        <AdvantageItemTitle icon={icon}>{title}</AdvantageItemTitle>
        <AdvantageItemText>{text}</AdvantageItemText>
      </Zoom>
    </AdvantageItemContainer>
  );
};

AdvantageItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default AdvantageItem;
