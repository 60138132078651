import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Zoom from 'react-reveal/Zoom';
import media from '../../utils/helpers/media';
import line from '../../assets/icons/zigzag.svg';
import useMedia from '../../hooks/useMedia';

const ProtectedItemContainer = styled.li`
  border: 1px solid rgba(121, 76, 250, 0.2);
  border-radius: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 321px;
  height: 321px;
  &:hover div {
    opacity: 1;
  }
  ${media.desktop`
    width: 327px;
    max-width: 100%;
    height: 327px;
    margin-bottom: 25px;
    align-items: center;
  ;`}
`;

const ProtectedItemHover = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 1;
  opacity: 0;
  padding: 20px;
  transition: opacity 0.5s;
  border-radius: 16px;
  border: 1px solid rgba(90, 76, 250, 0.2);
  background: #f9faff;
  cursor: pointer;
  animation-duration: 0.5s;
  animation-name: fadeIn;
`;

const ProtectedItemTitle = styled.h3`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  margin: ${props => (props.hasOffset ? '50px' : '40px')} 0 72px 6px;
  ${media.desktop`
    font-family: Gilroy;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.44px;
    margin: 26px 0 20px;
  ;`}
`;

const ProtectedItemText = styled.p`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  white-space: pre-line;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
  `}
`;

const ProtectedItemDescription = styled(ProtectedItemText)`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 132px;
  &:after {
    content: '';
    background: url(${line});
    background-repeat: no-repeat;
    width: 68px;
    height: 8px;
    margin-top: auto;
  }
  ${media.phone`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
  `}
`;

const ProtectedItemExceptionTitle = styled.p`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  margin: 27px 0 5px;
  ${media.phone`
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
  `}
`;

const ProtectedItemContent = styled.div`
  display: flex;
  flex-direction: column;
  animation-duration: 0.5s;
  animation-name: fadeIn;
`;

const ProtectedItem = ({
  item: { title, icon, list, exceptions, hasOffset, exceptionTitle }
}) => {
  const [isBackShown, setIsBackIshown] = useState(false);
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');

  const handleShowBack = () => {
    setIsBackIshown(!isBackShown);
  };
  return (
    <ProtectedItemContainer onClick={handleShowBack}>
      <Zoom>
        <ProtectedItemContent>
          <img src={icon} alt="" />
          <ProtectedItemTitle hasOffset={hasOffset}>{title}</ProtectedItemTitle>
        </ProtectedItemContent>
      </Zoom>
      {small && isBackShown && (
        <ProtectedItemHover>
          <ProtectedItemDescription>{list}</ProtectedItemDescription>
          <ProtectedItemExceptionTitle>
            {exceptionTitle}
          </ProtectedItemExceptionTitle>
          <ProtectedItemText>{exceptions}</ProtectedItemText>
        </ProtectedItemHover>
      )}
      {large && (
        <ProtectedItemHover>
          <ProtectedItemDescription>{list}</ProtectedItemDescription>
          <ProtectedItemExceptionTitle>
            {exceptionTitle}
          </ProtectedItemExceptionTitle>
          <ProtectedItemText>{exceptions}</ProtectedItemText>
        </ProtectedItemHover>
      )}
    </ProtectedItemContainer>
  );
};

ProtectedItem.propTypes = {
  item: PropTypes.shape({
    list: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    exceptions: PropTypes.string.isRequired,
    exceptionTitle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    hasOffset: PropTypes.bool
  }).isRequired
};

export default ProtectedItem;
