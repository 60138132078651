import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSection from '../components/Landing/HeroSection';
import AdvantageSection from '../components/Landing/AdvantageSection';
import ProtectedSection from '../components/Landing/ProtectedSection';
import PaymentTermsSection from '../components/Landing/PaymentTermsSection';
import ExamplesSection from '../components/Landing/ExamplesSection';

const IndexPage = () => (
  <Layout>
    <SEO title="Посадочная" />
    <HeroSection />
    <AdvantageSection />
    <ProtectedSection />
    <PaymentTermsSection />
    <ExamplesSection />
  </Layout>
);

export default IndexPage;
