import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import ProtectedItem from './ProtectedItem';
import vars from '../../utils/styles/vars';
import flats from '../../assets/icons/flats.svg';
import home from '../../assets/icons/home.svg';
import repair from '../../assets/icons/repair.svg';
import media from '../../utils/helpers/media';

const ProtectedWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(237, 239, 255, 0.37) 100%
  );
`;

const ProtectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0 75px;
  max-width: 1024px;
  margin: 0 auto;
  ${media.phone`
    padding: 17px 24px 32px;
  ;`}
`;

const ProtectedTitle = styled.h2`
  font-family: Gilroy;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.25px;
  margin-bottom: 57px;
  text-align: center;
  ${media.phone`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 46px;
  ;`}
`;

const ProtectedList = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.desktop`
    flex-direction: column;
    align-items: center;
  ;`}
`;

const ProtectedQuestion = styled.p`
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  max-width: 370px;
  margin: 50px 0 0;
  white-space: pre-line;
  ${media.phone`
    margin-top: 0;
    font-family: Avenir Next;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
  ;`}
`;

const ProtectedLink = styled(Link)`
  color: ${vars.colors.primaryColor};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const items = [
  {
    id: 1,
    title: 'Вещи внутри квартиры',
    icon: home,
    list:
      'Все вещи внутри жилья: мебель, электроннобытовая техника, одежда и украшения,\n до 150 000 ₽ за каждую вещь.',
    exceptions:
      'Вещей за пределами вашей квартиры, наличных денег, поломки бытовой техники.',
    exceptionTitle: 'Кроме:'
  },
  {
    id: 2,
    title: 'Ремонт в квартире',
    icon: repair,
    list:
      'Весь ремонт внутри квартиры, окна, стены, полы, трубы\n и батареи, включая инженерные коммуникации.',
    exceptions: 'Бетонного каркаса\n и несущих стен',
    exceptionTitle: 'Кроме:'
  },
  {
    id: 3,
    title: 'Квартира соседей',
    icon: flats,
    list:
      'Ремонт и все вещи в квартире соседей от любой неосторожности вас, друзей и ваших близких.',
    exceptions:
      'Выплата в пределах выбранного лимита, суммарно на всех пострадавших соседей',
    exceptionTitle: ' Ограничения:',
    hasOffset: true
  }
];

const ProtectedSection = () => {
  return (
    <ProtectedWrapper>
      <ProtectedContainer>
        <Fade top>
          <ProtectedTitle>Что защищено</ProtectedTitle>
        </Fade>
        <ProtectedList>
          {items.map(item => (
            <ProtectedItem key={item.id} item={item} />
          ))}
        </ProtectedList>
        <ProtectedQuestion>
          Еще остались вопросы?
          <br /> Читайте в разделе{' '}
          <ProtectedLink to="/conditions">условия и выплаты</ProtectedLink>
        </ProtectedQuestion>
      </ProtectedContainer>
    </ProtectedWrapper>
  );
};

export default ProtectedSection;
