import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../../utils/helpers/media';
import vars from '../../utils/styles/vars';

const ExamplesItemContainer = styled.div`
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  width: 290px;
  flex-shrink: 0;
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 24px;
  ${media.desktop`
    margin: 0;
    width: 264px;
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
  `}
`;

const ExamplesItemQuestion = styled.p`
  min-height: 78px;
  white-space: pre-line;
  ${media.desktop`
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    min-height: 60px;
  `}
`;

const ExamplesItemAnswer = styled.p`
  color: ${vars.colors.regentGray};
  ${media.desktop`
    font-family: Avenir Next;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  `}
`;

const ExamplesItemQuestionLabel = styled.p`
  margin-bottom: 16px;
  ${media.desktop`
    margin-bottom: 10px;
  `}
`;

const ExamplesItem = ({ item: { question, answer } }) => {
  return (
    <ExamplesItemContainer>
      <ExamplesItemQuestion>{question}</ExamplesItemQuestion>
      <ExamplesItemQuestionLabel>Заплатят?</ExamplesItemQuestionLabel>
      <ExamplesItemAnswer>{answer}</ExamplesItemAnswer>
    </ExamplesItemContainer>
  );
};

ExamplesItem.propTypes = {
  item: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired
  }).isRequired
};

export default ExamplesItem;
