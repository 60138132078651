import React from 'react';
import styled from 'styled-components';
import PaymentTermsItem from './PaymentTermsItem';
import media from '../../utils/helpers/media';
import insuranceCase from '../../assets/images/insurance-case.svg';
import noInsuranceCase from '../../assets/images/no-insurance-case.svg';
import HomeAction from '../Home/HomeAction';

const PaymentTermsContainer = styled.div`
  display: flex;
  padding: 98px 0 60px;
  max-width: 1024px;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  ${media.desktop`
    padding: 34px 15px 0;
    flex-direction: column;
  ;`}
`;

const HeroActionWrapper = styled.div`
  max-width: 1440px;
  margin: -20px auto 100px auto;
`;

const HeroActionDescription = styled.p`
  line-height: 24px;
  text-align: center;
  max-width: 667px;
  margin: 34px auto;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #818a97;
  margin-bottom: 22px;
  ${media.phone`
    width: 300px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.44px;
    margin: 30px auto 16px auto;
  `}
`;

const caseList = [
  {
    id: 1,
    title: 'Когда платим',
    text:
      'Манго платит во всех страховых случаях, которые произошли не зависимо от вас и не по вашей вине',
    image: insuranceCase,
    isCase: true
  },
  {
    id: 2,
    title: 'Когда не платим',
    text:
      'Манго не компенсирует страховые случаи, которые произошли по вашей вине или халатности',
    image: noInsuranceCase,
    isCase: false
  }
];

const PaymentTermsSection = () => {
  return (
    <>
      <PaymentTermsContainer>
        {caseList.map(item => (
          <PaymentTermsItem key={item.id} item={item} />
        ))}
      </PaymentTermsContainer>

      <HeroActionWrapper>
        <HeroActionDescription>
          Оформите полис, подходящий именно для вашей квартиры
        </HeroActionDescription>
        <HomeAction mobileButton landing />
      </HeroActionWrapper>
    </>
  );
};

export default PaymentTermsSection;
